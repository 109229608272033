import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import orderHelpers from '../../../helpers/orders';
import bulkHelpers from '../../../helpers/bulk';
import ProcessingPanel from './ProcessingPanel';

function ProcessingDrawer(props) {
    const [orders, setOrders] = useState([]);
    const [progress, setProgress] = useState(true);
    const [default_wh, setDefaultWh] = useState("Pennsylvania");
    const [other_wh, setOtherWh] = useState("Kentucky");
    const [all_cancel, setAllCancel] = useState(false);
    const [needs_split, setNeedsSplit] = useState(false);
    const [pallets, setPallets] = useState(0);
    const [unknownPallets, setUnknownPallets] = useState(0);
    const [palletCalculator, setPalletCalculator] = useState({})

    
    const calculatePallets = async(orders, palletCalc) => {
        let unknownOut = 0;
        let out = 0;
        for(let order of orders) {

            for(let item of order.items) {
                let dim = item?.default_box in palletCalc ? palletCalc[item.default_box] : palletCalc[item.internal_sku];
                if(!dim) {
                    unknownOut++;
                    continue;
                }
                if(item.decision === "Accept" || item.decision === "Pennsylvania" || item.decision === "Kentucky") {
                    out += item.boxes / dim;
                }
                else if(item.decision === "Split" || item.decision === "Partial") {
                    let qty_per_box = item.quantity / item.boxes;
                    let qty_per_pallet = qty_per_box * dim;
                    out += (item.fulfill_ky || 0 + item.fulfill_pa || 0) / qty_per_pallet
                }
            }
        }
        setPallets(Math.ceil(out));
        setUnknownPallets(unknownOut);
    }

    
    const getPallets = async(type, orders) => {
        let out = {};
        try {
            let res = await bulkHelpers.getPalletCalculator(type, props.company + "_bulk", props.token);
            const zlib = require("zlib")
            let data = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
            for(let row of data.data) {
                out[row.box] = row.qty_per_pallet;
            }
            setPalletCalculator(out);
            await calculatePallets(orders, out);
        }
        catch(err) {
            console.log(err);
        }
    }


    useEffect(() => {
        const getOrder = async() => {
            let inv = await getItems();
            let full_out = [];
            let i = 1;
            for(let order_obj of props.order) {
                let body = {internal_po_number: order_obj.internal_po_number, vendor_name: order_obj.vendor_name}
                // if(props.ignore_cancel) {
                //     body.ignore_cancel = true;
                //     console.log("Ignore cancel");
                // }
                let res = await orderHelpers.getBulkOrders(props.company + "_bulk", body, props.token);
                if(!res || res.length === 0) {
                    setOrders([]);
                    return;
                }
                let out = res[0];
                out.items = [];
                let skus = [...new Set(res.map(obj => obj.internal_sku))];
                let total = 0;
                let pieces = 0;
                
                for(let sku of skus) {
                    let items = res.filter(obj => obj.internal_sku === sku);
                    let curr_inv = inv[sku];
                    let count = items.reduce((a, b) => a + b.quantity, 0);
                    let item = items[0];
                    let decision = await getDefaultDecision(curr_inv, count)
                    let params = items.map(obj => JSON.parse(obj.vendor_params || "{}"));
                    let note = params.filter(obj => obj.note);
                    out.items.push({
                        id: i,
                        internal_po_number: order_obj.internal_po_number,
                        internal_sku: item.internal_sku,
                        external_sku: item.external_sku,
                        external_price: item.external_price,
                        internal_price: item.internal_price,
                        quantity: count,
                        kentucky: curr_inv?.Kentucky || 0,
                        pennsylvania: curr_inv?.Pennsylvania || 0,
                        decision: decision,
                        case_packs: items.length,
                        default_box: item.default_box,
                        acu_order_num: out.acu_order_num,
                        boxes: items.length,
                        vendor_params: item.vendor_params,
                        tooltip: note[0] ? note[0].note : ""
                    });
                    pieces += count;
                    total += (item.internal_price > 0 ? item.internal_price : item.external_price) * count;
                    i++;
                }
                out.total_price = Math.round(total * 100) / 100;
                out.total_pieces = pieces;

                full_out.push(out);
            }
            if (props.order.some(obj => obj.vendor_name.toLowerCase().includes("chewy"))) {
                getPallets("chewy", full_out);
            } 
            setOrders(full_out);
            if(full_out.every(outer => outer.items.every(obj => obj.decision === "Cancel"))) setAllCancel(true);
            else setAllCancel(false);
            if(full_out.every(outer => outer.items.every(obj => obj.decision !== "Split" && obj.decision !== "Partial"))) setNeedsSplit(false);
            else setNeedsSplit(true);
            
        }


        const getItems = async() => {
            let result = await bulkHelpers.getBulkInventory(props.company + "_bulk", props.token);
            let res = result.data;
            if(res.length === 0) {
                // setInv({})
                return {};
            }
            let out = {};
            for(let item of res) {
                out[item.sku] = item;
            }
            // setInv({out});
            return out;
        }
        const getDefaultDecision = async(inv, qty) => {
            if(!inv) return "Pennsylvania"; // Default
            let def = inv[default_wh];
            let oth = inv[other_wh];
            if(def >= qty) {
                return default_wh;
            }
            if(oth >= qty) {
                return other_wh;
            }
            if(oth+def >= qty) {
                return "Split";
            }
            if(oth+def > 0) {
                return "Partial";
            }
            return "Cancel"
        }
        getOrder();
    }, [])
    
    const confirmClose = async() => {
        if(progress) {
            if(window.confirm("Close processing panel? This will lose any unsaved progress.")) {
                props.setProcessing(false)
            }
        }
        else {
            props.setProcessing(false)
        }
    }



    const rejectOrder = async() => {
        if(window.confirm("WARNING: Reject entire order?")) {
            // Reject the whole thing.
        }
    }

    return(
        <Drawer open={props.processing} onClose={() => confirmClose()} anchor="bottom">
            {orders.length > 0 ? 
            <Box style={{height: "600px", width:"75%"}}  display="flex" sx={{m:"auto", mb:"80px", pt:"20px"}}>
                <List dense>
                    <ListItem>
                        {/* <ListItemText primary="Order Number" primaryTypographyProps={{variant:"h4"}} secondaryTypographyProps={{variant:"h5"}} secondary={order.internal_po_number} /> */}
                    </ListItem>
                    <ListItem>
                        {/* <ListItemText primary="Order Date" secondary={order.order_date ? order.order_date.substring(0, 10) : "N/A"}/> */}
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Vendor" secondary={orders[0].vendor_name} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Pieces" secondary={orders.reduce((a,b) => a + b.total_pieces, 0)} />
                    </ListItem>
                    {orders[0].vendor_name.toLowerCase().includes("chewy") ?                     
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: {color: unknownPallets > 0 || pallets > 49 ? "red" : "green"} }} secondaryTypographyProps={{ style: {color: unknownPallets > 0 || pallets > 49 ? "red" : "green"} }} primary="Pallets" secondary={unknownPallets > 0 ? `${pallets || 0} Pallets | ${unknownPallets} Unknown` : pallets} />
                    </ListItem>
                    : null}

                    <ListItem>
                        <ListItemText 
                        primaryTypographyProps={{ style: {color: orders.reduce((a,b) =>a + b.total_price, 0)  > 100 ? "green" : "red"} }} 
                        secondaryTypographyProps={{ style: {color: orders.reduce((a,b) =>a + b.total_price, 0)  > 100 ? "green" : "red"} }} 
                        primary="Total Price" 
                        secondary={`$${orders.reduce((a,b) =>a + b.total_price, 0)}`} />
                    </ListItem>
                    {/* <Button onClick={()=>rejectOrder()} style={{marginTop:"20px"}} variant="contained" color="error">Reject Order</Button> */}
                </List>
                <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                <ProcessingPanel {...props} palletCalculator={palletCalculator} setPalletCalculator={setPalletCalculator} calculatePallets={calculatePallets} setUnknownPallets={setUnknownPallets} setPallets={setPallets} orders={orders} setOrders={setOrders} all_cancel={all_cancel} setAllCancel={setAllCancel} needs_split={needs_split} setNeedsSplit={setNeedsSplit} />

            </Box> :
            <Box display="flex" style={{height:"100px"}}>
                <CircularProgress style={{margin:"auto"}} />
            </Box>}
        </Drawer>
    )
}

export default ProcessingDrawer;