import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {
        field: 'vendor_name',
        headerName: 'Vendor',
        flex: 1, 
        minWidth: 150,
        editable: false
    },
    {
        field: 'internal_po_number',
        headerName: 'PO Number',
        flex: 1,
        minWidth: 75,
        editable: false
    },  
    {
        field: 'upload_date',
        headerName: 'Upload Date',
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => {
            if(!params.row.upload_date) return "";
            return params.row.upload_date.substring(0, 10)
        }, 
        editable: false
    },
    {
        field: 'order_date',
        headerName: 'Order Date',
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => {
            if(!params.row.order_date) return "";
            return params.row.order_date.substring(0, 10)
        }, 
        editable: false
    },
    {
        field: 'tracking_number',
        headerName: 'Tracking Number',
        flex: 1,
        minWidth: 75,
        renderCell: (params) => {
            if (!params.row.shipments) return "N/A";
            if (params.row.shipments.length > 1) return "Multiple";
            if (params.row.shipments[0].tracking_number === null) return "N/A";
            
            const trackingNumber = params.row.shipments[0].tracking_number;
            let trackingUrl = trackingNumber;
    
            if (trackingNumber.length === 12) {
                trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`;
            } else if (trackingNumber.length === 18) {
                trackingUrl = `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}`;
            }
    

            return (
                <div>
                    {trackingUrl && trackingUrl != trackingNumber ? (
                        <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                            {trackingNumber}
                        </a>
                    ) : (
                        <span>{trackingNumber}</span>
                    )}
                </div>
            );
        },
        editable: false
    }, 
    {
        field:'order_status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => {
            let name = params.row.order_status;
            while(name.includes('_')){
                let back = name.substring(name.indexOf("_") + 1);
                name = name[0].toUpperCase() + name.substring(1, name.indexOf("_")) + " " + back[0].toUpperCase() + back.substring(1)
            }
            return name;
        },
        editable: false
    },
    {
        field:'tracking_status',
        headerName: 'Tracking',
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => {
            let name = params.row?.shipments[0].tracking_status;
            while(name.includes('_')){
                let back = name.substring(name.indexOf("_") + 1);
                name = name[0].toUpperCase() + name.substring(1, name.indexOf("_")) + " " + back[0].toUpperCase() + back.substring(1)
            }
            return name;
        },
        editable: false
    },
    {
        field: 'truck_number',
        headerName: 'Truck',
        flex: 1,
        minWidth: 75,
        valueGetter: (params) => {
            return params.row?.shipments[0].truck_number
        }, 
        editable: false
    }
]

function OrderSearchTable(props) {

    return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"}>
            <Box className = {props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                <DataGrid
                    className = {props.isMobile ? "mobile-table" : "desktop-table"}
                    sx = {{height:"1000px"}}
                    rows = {props.orders}
                    columns = {columns}
                    rowsPerPageOption = {[25, 50, 100]}
                    checkboxSelection = {false}
                    onRowClick = {props.setOpenDetails}
                />
            </Box>
        </Box>
    )
}

export default OrderSearchTable;