import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import './lots.css';
import { isItemValid } from './OrderUtils';


const columns = [{
    field: 'vendor_name',
    headerName: "Vendor",
    flex: 1,
    editable: false,
    minWidth: 200,
    maxWidth: 200
}, {
    field: 'internal_po_number',
    headerName: "PO",
    flex: 1,
    editable: false,
    minWidth: 200,
    maxWidth: 200
}, {
    field: 'upload_date',
    headerName: "Upload Date",
    flex: 1,
    editable: false,
    minWidth: 200,
    maxWidth: 200
}, {
    valueGetter: (value) => {
        const orderItems = value.row.items;
        const notCancelledItems = orderItems.filter(item => item.order_status !== 199);
        const validItemsCount = notCancelledItems.filter(isItemValid).length;
        return `${validItemsCount} / ${notCancelledItems.length}`;
    },
    field: 'valid_items', 
    headerName: "Valid expiration date and lot number",
    flex: 1,
    editable: false,
    minWidth: 250,
    maxWidth: 250,
    align: 'center'
}, {
    valueGetter: (value) => {
        const vendor_status_codes = [{value: 300, name: 'Not Sent'}, {value: 301, name: 'Sent'}, {value: -301, name: 'Failed'}];
        const vendor_status = value.row.vendor_status;
        const status = vendor_status_codes.find(status => status.value === vendor_status);
        return status ?  status.name : '';
    },
    field: 'vendor_status',
    headerName: "ASN Status",
    flex: 1,
    editable: false,
    minWidth: 200,
    maxWidth: 200,
    headerAlign: 'center',
    align: 'center'
}];


function OrderTable(props) {

    const rows = props.orders.map(order => ({
        ...order,
        id: order.internal_po_number
    }));

    const getRowClassName = params => {
        const orderItems = params.row.items;
        const vendor_status = params.row.vendor_status;
        const notCancelledItems = orderItems.filter(item => item.order_status !== 199);
        const validItemsCount = notCancelledItems.filter(isItemValid).length;
        return vendor_status === 301 ? "asn-sent-table-row" : validItemsCount === notCancelledItems.length ? "green-table-row" : "red-table-row";
    };

    return (
        <Box className = {props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
            <DataGrid
                style = {{width:"70%", marginLeft:"0%"}}
                rows = {rows}
                className = {props.isMobile ? "mobile-table" : "desktop-table"}
                columns = {columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                getRowClassName = {getRowClassName}
                onRowClick = {event => props.displayOrderItems(event.id)}
            />
        </Box>
    );
};

export default OrderTable;