import { isItemValid } from './OrderUtils';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';


const OrderDetails = props => {

    const [shortItemInfo, setShortItemInfo] = useState({});
    const [unshortItemInfo, setUnshortItemInfo] = useState({});

    const getItemRowClassName = params => {
        const itemInfo = params.row;
        const order_status = params.row.order_status;
        if (order_status === 199) {
            return 'cancelled-row';
        }
        return isItemValid(itemInfo) ? "green-table-row" : "red-table-row";
    };

    const handleShort = () => {
        props.shortItem({
            internal_sku: shortItemInfo.internal_sku,
        });
        setShortItemInfo({});
    };

    const handleUnshort = () => {
        props.unshortItem({
            internal_sku: unshortItemInfo.internal_sku,
        });
        setUnshortItemInfo({});
    };

    const itemTableColumns = [{
        field: 'internal_sku',
        headerName: "Internal SKU",
        flex: 1,
        editable: false,
        minWidth: 150,
        maxWidth: 150,
        headerAlign: 'center',
        align: 'center'
    }, {
        field: 'description',
        headerName: "Description",
        flex: 1,
        editable: false,
        minWidth: 300,
        maxWidth: 300
    }, {
        field: 'expiration_date',
        headerName: "Expiration date",
        flex: 1,
        editable: false,
        minWidth: 200,
        maxWidth: 200
    }, {
        field: 'lot_id',
        headerName: "Lot ID",
        flex: 1,
        editable: false,
        minWidth: 100,
        maxWidth: 100
    }, {
        field: 'lot_number',
        headerName: "Lot number",
        flex: 1,
        editable: false,
        minWidth: 120,
        maxWidth: 120
    }, {
        valueGetter: (value) => {
            const order_status_codes = [
                {value: 100, name: 'Created'}, 
                {value: 101, name: 'Acknowledged'}, 
                {value: 102, name: 'Built'},
                {value: 103, name: 'Staged'}, 
                {value: 104, name: 'Shipped'}, 
                {value: 197, name: 'On Hold'}, 
                {value: 199, name: 'Cancelled'}, 
            ];
            const order_status = value.row.order_status;
            const status = order_status_codes.find(status => status.value === order_status);
            return status ?  status.name : '';
        },
        field: 'order_status',
        headerName: "Order Status",
        flex: 1,
        editable: false,
        minWidth: 120,
        maxWidth: 120
    }, {
        field: "short_button",
        headerName: "Short",
        minWidth: 150,
        maxWidth: 150,
        headerAlign: 'center', 
        align: 'center', 
        renderCell: params => {
            return (
                params.row.order_status === 102 ? 
                    <Tooltip title = {props.selectedOrder?.vendor_status === 301 ? "ASN has been sent. Can't edit order." : ""} placement = 'right'>
                        <span style = {{ display: 'inline-block', float: 'right' }}>
                            <Button 
                                variant = "contained" 
                                style = {{width: '100px', backgroundColor: props.selectedOrder?.vendor_status === 301 ? "#cccaca" : "#666666", color: props.selectedOrder?.vendor_status === 301 ? "#aba9a9" : "#FFFFFF"}}  
                                onClick = {() => setShortItemInfo(params.row)} 
                                disabled = {props.selectedOrder?.vendor_status === 301 ? true : false}>
                                    Short
                            </Button> 
                        </span>
                    </Tooltip> :
                params.row.order_status === 199 ? 
                    <Tooltip title = {props.selectedOrder?.vendor_status === 301 ? "ASN has been sent. Can't edit order." : ""} placement = 'right'>
                        <span style = {{ display: 'inline-block', float: 'right' }}>
                            <Button 
                                variant = "contained" 
                                style = {{width: '100px', backgroundColor: props.selectedOrder?.vendor_status === 301 ? "#cccaca" : "#666666", color: props.selectedOrder?.vendor_status === 301 ? "#aba9a9" : "#FFFFFF"}}  
                                onClick = {() => setUnshortItemInfo(params.row)} 
                                disabled = {props.selectedOrder?.vendor_status === 301 ? true : false}>
                                    Unshort
                            </Button>  
                        </span>
                    </Tooltip> : 
                null
            );
        }
    }];

    const itemTableRows = props.selectedOrder.items?.map(item => ({
        ...item,
        id: item.internal_sku
    }));

    return (
        <>
            <Dialog open = {shortItemInfo.internal_sku !== undefined} onClose = {() => setShortItemInfo({})}>
                <DialogTitle>Are you sure you want to short the SKU?</DialogTitle>
                <DialogActions>
                    <Button variant = "contained" color = "success" onClick = {handleShort}>Short SKU</Button>
                    <Button variant = "contained" color = "error" onClick={() => setShortItemInfo({})}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {unshortItemInfo.internal_sku !== undefined} onClose = {() => setUnshortItemInfo({})}>
                <DialogTitle>Are you sure you want to unshort the SKU?</DialogTitle>
                <DialogActions>
                    <Button variant = "contained" color = "success" onClick = {handleUnshort}>Unshort SKU</Button>
                    <Button variant = "contained" color = "error" onClick = {() => setUnshortItemInfo({})}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Drawer open={Object.keys(props.selectedOrder).length > 0} onClose={() => props.setSelectedOrder({})} anchor="bottom">
                {Object.keys(props.selectedOrder).length > 0 && <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: "500px", width: "80%", marginLeft: "10%" }}>
                    <h2>{props.selectedOrder.vendor_name} PO# {props.selectedOrder.internal_po_number}
                        <Tooltip title = {!props.isComplete ? "Upload all the lot numbers and expiration dates to regenerate the labels!" : ""} placement = 'left'>
                            <span style = {{ display: 'inline-block', float: 'right' }}>
                                <Button 
                                    sx = {{
                                        backgroundColor: '#7d5265',
                                        '&:hover': { 
                                        backgroundColor: '#6b4758'
                                        }}} 
                                    variant = "contained" 
                                    disabled = {!props.isComplete}
                                    onClick = {() => props.regenerateBoxLabels()}>
                                    Regenerate Box Labels
                                </Button>
                            </span>
                        </Tooltip>
                    </h2>
                    <h4>Upload Date: {props.selectedOrder.upload_date}</h4>
                    <DataGrid
                        style={{ width: "100%" }}
                        rows={itemTableRows}
                        className={props.isMobile ? "mobile-table" : "desktop-table"}
                        columns={itemTableColumns}
                        rowsPerPageOption={[25, 50, 100]}
                        checkboxSelection={false}
                        getRowClassName={getItemRowClassName}
                        onRowClick={() => { }}
                    />
                </Box>}
            </Drawer>
        </>
    );
};

export default OrderDetails;