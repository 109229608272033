import React, {useRef, useState, useEffect} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DetailsPackage from './DetailsPackage';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography'
import orderHelpers from '../../helpers/orders';
import TransferDialog from './TransferDialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import EditOrderInfoPopup from './EditOrderInfoPopup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

function OrderDetails(props) { 

    const order = props.order || {};
    const containerRef = useRef({});

    const [status, setStatus] = useState({});
    const [transferOpen, setTransferOpen] = useState(false);
    const [openEditOrderInfoPopup, setOpenEditOrderInfoPopup] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setStatus(order.order_status === "order_created" ? {name: "Order Created", class: "created"} :
        order.order_status === "order_pulled" ? {name: "Items Pulled", class: "pulled"} :
        order.order_status === "order_built" ? {name: "Items Built", class: "built"} :
        order.order_status === "order_staged" ? {name: "Order Staged", class: "staged"} :
        order.order_status === "order_shipped" ? {name: "Order Shipped", class: "shipped"} : 
        order.order_status === "order_cancelled" ? {name: "Order Cancelled", class: "cancelled"} :
        order.order_status === "order_production_hold" ? {name: "Order Production Hold", class: "onHold"} : {})
    }, [order.order_status])

    const resendAcumatica = async() => {
        if(!window.confirm(`Resend order ${order.internal_po_number} to Acumatica? This will delete existing shipments and cancel the existing order.`)) return;
        props.setLoading(true);
        await orderHelpers.resendAcumatica(order.internal_po_number, props.company, props.token);
        props.setLoading(false);
    }

    const cancelOrder = async() => {
        try{
            if(!window.confirm(`Cancel order ${order.internal_po_number}?`)) {
                throw Error("Operation Aborted");
            }
            props.setLoading(true);
            let res = await orderHelpers.cancelOrder(order.internal_po_number, order.vendor_name, props.company, props.token);
            if(res.success) setStatus({name: "Order Cancelled", class: "cancelled"});
            else throw new Error("Can't Cancel Order.\n Check if order is on a pullsheet")
        }
        catch(e){
            throw Error(e);
        }
        finally{
            props.setLoading(false);
        }
    }

    const saveHoldOrder = async() => {
        if(!window.confirm(`Hold order ${order.internal_po_number}?`)) return;
        props.setLoading(true);
        let res = await orderHelpers.holdOrder(order.internal_po_number, order.vendor_name, props.company, props.token);
        if(res.success){
            setStatus({name:"Order On Hold", class:"hold"});
        }
        props.setLoading(false);
    }

    const holdOrder = async(e) => {
        setError("");
        props.toast.promise(saveHoldOrder(), {
            loading: "Uploading...",
            success: (res) => `Order On Hold!`,
            error: (err) => `${err.message}`
        })
    }

    const cancelOrderToast = async(e) => {
        setError("");
        try{
            await props.toast.promise(cancelOrder(), {
            loading: "Uploading...",
            success: () => `Order Canceled!`,
            error: (err) => `${err.message}`
            });
        }    
        catch (error) {
            console.error("Unexpected error:", error);
        }
    }

    const transfer = async(items) => {
        props.setLoading(true);
        let out = [];
        for(let item in items) {
            if(items[item]) {
                out.push(item);
            }
        }
        await orderHelpers.transferItems(out, order.internal_po_number, props.company, props.token);
        props.setLoading(false);
        setTransferOpen(false);
        console.log("Order transferred!");

    }
    
    return (
        <Drawer open = {Object.keys(order).length > 0} onClose = {() => props.setOpenDetails()} anchor = "bottom">
            <TransferDialog {...props} items = {order.items} open = {transferOpen} setOpen = {setTransferOpen} transfer = {transfer} />
            {openEditOrderInfoPopup ? <EditOrderInfoPopup
                {...props}
                open = {openEditOrderInfoPopup} 
                handleClose = {() => setOpenEditOrderInfoPopup(false)} 
                po = {order.internal_po_number} 
                /> : null}
            {Object.keys(order).length > 0 && 
                <Box style = {{ height: "60vh" }}  display = "flex" sx = {{m: "auto", mb: "80px", pt: "20px"}}>
                    {/* Order Information */}
                    <Card style = {{ height: "60vh", overflowY: "auto", overflowX: 'auto' }} sx = {{ margin: 2, width: "30vw",  mr: "10px" }}>
                        <CardContent>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -2 }}>
                                <Typography gutterBottom variant = "h5" component = "div" >
                                    Order Information
                                </Typography>
                                <Box sx = {{ display: 'flex', alignItems: 'center',  mr:"5px"}}>
                                    <Chip
                                        label = "Dropship"
                                        variant = 'filled'
                                        sx = {{
                                            mr: 1, 
                                            bgcolor: '#868f84',
                                            color:  'white',
                                            borderColor: '#868f84'
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx = {{ mt: -6, p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'text.secondary' }}>
                                <Typography gutterBottom variant="body2" component="div" >
                                    {order.order_date ? order.order_date.substring(0, 10) : "N/A"}
                                </Typography>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6, mt: -1 }} variant = "fullwidth" />
                            <Box sx = {{ p: "20px", width: "100%" }}>
                                <List dense>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "Vendor"
                                            secondary = {order.vendor_name ? order.vendor_name : "N/A" }
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ListItemText
                                            primary = "PO Number"
                                            secondary = {order.internal_po_number ? order.internal_po_number : "N/A" }
                                            sx = {{
                                                mt: -1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                paddingLeft: 2,  
                                                paddingRight: 2, 
                                            }}
                                        />
                                    </ListItem>
                                    <Stack direction = "row"  alignItems = "center" spacing = {1}> 
                                        
                                        <ListItem disableGutters sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <ListItemText
                                                primary = "Acumatica SO Number"
                                                secondary = {order.acu_order_num ? order.acu_order_num : 
                                                    <Tooltip title = {order.acu_order_num ? "Cannot edit." : "Edit."} placement = "left">
                                                        <span>
                                                            <Button
                                                                size = "small"
                                                                sx = {{ mt: -1}} 
                                                                disabled = {order.acu_order_num ? true : false}
                                                                onClick = {() => setOpenEditOrderInfoPopup(true)}
                                                                endIcon = {<EditIcon />}
                                                                color = "error"
                                                            >
                                                            N/A
                                                            </Button>
                                                        </span>
                                                    </Tooltip>}
                                                sx = {{
                                                    mt: -1,
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                    minWidth: '150px',
                                                }}
                                            />
                                        </ListItem>
                                    </Stack>

                                   
                                </List>
                            </Box>
                            <Divider flexItem />
                                <Box sx = {{ display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "flex-start", gap: "10px", mt: 2, mb: 2 }}>
                                    {status.name !== "Order Shipped" && status.name !== "Order Cancelled" ? 
                                        <Button 
                                            sx = {{ whiteSpace: "nowrap", width: "150px", flexShrink: 0 }} 
                                            disabled = {props.isLoading} 
                                            variant = "contained" 
                                            onClick = {() => cancelOrderToast()} 
                                            className = {`${props.warehouse} ${props.company} error`}>
                                            Cancel Order
                                        </Button> 
                                    : null}

                                    {status.name !== "Order Production Hold" && status.name !== "Order Cancelled" ? 
                                        <Button 
                                            sx = {{ whiteSpace: "nowrap", width: "150px", flexShrink: 0 }}  
                                            disabled = {props.isLoading} 
                                            variant = "contained" 
                                            onClick = {() => holdOrder()} 
                                            className = {`${props.warehouse} ${props.company} warning`}>
                                            Hold Order
                                        </Button> 
                                    : null}

                                    {status.name !== "Order Cancelled" ? 
                                        <Button 
                                            sx = {{ whiteSpace: "nowrap", width: "200px", flexShrink: 0 }} 
                                            variant = "contained" 
                                            disabled = {props.isLoading} 
                                            className = {`${props.warehouse} ${props.company} tertiary`} 
                                            onClick = {() => resendAcumatica()}>
                                            Resend to Acumatica
                                        </Button> 
                                    : null}

                                    {status.name !== "Order Cancelled" ? 
                                        <Button 
                                            sx = {{ whiteSpace: "nowrap", width: "150px", flexShrink: 0 }}
                                            variant = "contained" 
                                            disabled = {props.isLoading} 
                                            className = {`${props.warehouse} ${props.company} secondary`} 
                                            onClick = {() => setTransferOpen(true)}>
                                            Transfer
                                        </Button> 
                                    : null} 
                                </Box>
                            <Divider flexItem />
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography gutterBottom variant = "h5" component="div" >
                                    Address Information
                                </Typography>
                            </Box>    
                            <Divider sx = {{ width: "100%", opacity: 0.6, mt: -1}} variant = "fullWidth" />
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'text.secondary', mt: -1}}>
                                {order.name} <br />
                                {order.address1} <br />
                                {order.address2 && <>{order.address2}<br /></>}
                                {order.city}, {order.state} {order.zip_code} <br />
                            </Box>
                        </CardContent>    
                    </Card>
                    {/*Package Information */}
                    <Card style = {{ height: "60vh", overflowY: "auto", overflowX: 'auto' }} sx = {{ margin: 2, width: "45vw",  mr: "10px" }}>
                        <CardContent>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -2.5, mb: -3.8}}>
                                <Typography gutterBottom variant = "h5" component = "div" >
                                    Package Information
                                </Typography>
                            </Box>
                            <Box sx = {{ p: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: -2.5}}>
                                <Tabs 
                                    value = {props.tab} 
                                    onChange = {props.setTab}
                                    variant = "scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label = "scrollable force tabs example"
                                    textColor = "inherit"
                                    sx = {{
                                        mt: -3,
                                        '& .MuiTabs-indicator': {
                                        backgroundColor: '#889285',
                                        },
                                        '& .MuiTab-root': {
                                        color: '#c2c4c1',
                                        },
                                        '& .Mui-selected': {
                                        color: '#889285',
                                        },
                                    }}>
                                    {order.shipments?.map((shipments, index) => {
                                        return(
                                                <Tab value = {index} name = {index} key = {index} label = {`Package ${index + 1}`} />
                                        )
                                    })}
                                </Tabs>
                            </Box>
                            <Divider sx = {{ width: "100%", opacity: 0.6}} variant = "fullWidth" />
                            {order.shipments.map((shipment, index) => {
                            return(
                                <Box ref = {containerRef[index]} key = {index} display = "flex" flexDirection = "column" sx = {{mt: 0}}>
                                    <Slide mountOnEnter unmountOnExit container = {containerRef[index]?.current} direction = {props.direction} in = {props.tab === index}>
                                        <Box>
                                            {props.tab === index ? 
                                                <DetailsPackage 
                                                    {...props} 
                                                    status = {status} 
                                                    internal_po_number = {order.internal_po_number} 
                                                    packageNum = {props.tab} 
                                                    shipment = {shipment} 
                                                />  
                                            : null}
                                        </Box>
                                    </Slide>
                                </Box>
                            )
                        })}
                        </CardContent>
                    </Card>
                </Box>
            }
        </Drawer>
    );
};

export default OrderDetails;


