import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OrderQueryForm from './OrderQueryForm'
import OrderSearchTable from './OrderSearchTable';
import orderHelpers from '../../helpers/orders';
import vendorHelpers from '../../helpers/vendors';
import OrderDetails from './OrderDetails';

function OrderSearch(props) {

    const [vendorList, setVendorList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vendor, setVendor] = useState('');
    const [acumaticaOrderNum, setAcumaticaOrderNum] = useState('');
    const [uploadId, setUploadId] = useState('');
    const [pullsheetId, setPullsheetId] = useState('');
    const [internalPoNumber, setInternalPoNumber] = useState('');
    const [externalPoNumber, setExternalPoNumber] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [openDetails, setOpenDetail] = useState(null);
    const [orders, setOrders] = useState([]);
    const [detailsTab, setDetailsTab] = useState(null);
    const [direction, setDirection] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');
    const [acumaticaStatus, setAcumaticaStatus] = useState('');
    const [trackingStatus, setTrackingStatus] = useState('');
    const [warehouse, setWarehouse] = useState('');

    useEffect(() => {
        const getVendors = async() => {
            let vendorList = await vendorHelpers.getVendors(props.company, props.token)
            setVendorList(vendorList.vendors)
        }
        getVendors();
    }, [])

    const clearAllFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setVendor('');
        setAcumaticaOrderNum('');
        setInternalPoNumber('');
        setExternalPoNumber('');
        setSku('');
        setName('');
        setTrackingNumber('');
        setUploadId('');
        setPullsheetId('');
        setWarehouse('');
        setOrderStatus(null);
        setAcumaticaStatus('');
        setTrackingStatus('');
        setOrders([]);
    }

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`
        eval(func)(event.target.value)
    }

    const handleDateChange = (date, field) => {
        let func = setStartDate;
        if(field === "endDate") func = setEndDate
        if(date !== null){
            func({formatted: date.format("YYYY-MM-DD"), full: date})
        } else {
            func(date)

        }
    }

    const formatOrders = async(orders) => {
        let pos = {};
        for(let order of orders) {
            let po = order.internal_po_number;
            if(!(po in pos)) {
                pos[po] = {
                    acu_order_num: order.acu_order_num,
                    address1: order.address1,
                    address2: order.address2,
                    city: order.city,
                    external_po_number: order.external_po_number,
                    internal_po_number: po,
                    name: order.name,
                    order_date: order.order_date,
                    order_status: order.order_status,
                    state: order.state,
                    upload_date: order.upload_date,
                    vendor_name: order.vendor_name,
                    zip_code: order.zip_code,
                    shipments: {},
                    items: []
                }
            }
            let tn = order.tracking_number;
            if(!(order.tracking_number in pos[po].shipments)) {
                pos[po].shipments[tn] = {
                    acu_ship_num: order.acu_ship_num,
                    acumatica_status: order.acumatica_status,
                    carrier: order.carrier,
                    method: order.method,
                    date: order.upload_date,
                    truck_number: order.truck_number,
                    tracking_number: tn,
                    tracking_status: order.tracking_status,
                    tote_number: order.tote_number,
                    vendor_status: order.vendor_status,
                    warehouse: order.warehouse,
                    total_on_shipment: order.total_on_shipment,
                    pullsheet_id: order.pullsheet_id,
                    reshipped_date: order.reshipped_date,
                    items: {}
                }
            }
            let sku = order.internal_sku;
            if(!(sku in pos[po].shipments[tn].items)) {
                pos[po].shipments[tn].items[sku] = {
                    external_sku: order.external_sku,
                    internal_price: order.internal_price,
                    external_price: order.external_price,
                    internal_sku: sku,
                    quantity: 0
                }
            }
            pos[po].items.push({order_id:order.order_id, description:order.description, internal_sku: sku})
            pos[po].shipments[tn].items[sku].quantity += order.quantity;
        }
        let out = [];
        let id = 1;
        for(let po in pos) {
            let shipments = [];
            for(let tn in pos[po].shipments) {
                let items = [];
                for(let sku in pos[po].shipments[tn].items) {
                    items.push(pos[po].shipments[tn].items[sku]);
                }
                pos[po].shipments[tn].items = items;
                shipments.push(pos[po].shipments[tn]);
            }
            pos[po].shipments = shipments;
            pos[po].id = id;
            out.push(pos[po]);
            id++;
        }

        out.sort((a, b) => {
            let dateA = new Date(a.upload_date);
            let dateB = new Date(b.upload_date);
            return dateB - dateA;
        });

        return out;
    }

    const getOrders = async () => {
        props.setLoading(true);
        let params = {
            start_date: startDate !== null ? startDate.formatted : null, 
            end_date: endDate !== null ? endDate.formatted : null, 
            vendor_name: vendor !== '' ? vendor : null, 
            acu_order_num: acumaticaOrderNum !== '' ? formatAcuOrderNum(acumaticaOrderNum) : null,
            upload_id: uploadId !== '' ? uploadId : null, 
            pullsheet_id: pullsheetId !== '' ? pullsheetId : null, 
            internal_po_number: internalPoNumber !== '' ? internalPoNumber : null, 
            external_po_number: externalPoNumber !== '' ? externalPoNumber : null, 
            tracking_number: trackingNumber !== '' ? trackingNumber : null, 
            name: name !== '' ? name : null,
            sku: sku !== '' ? sku : null,
            order_status: orderStatus !== '' ? orderStatus : null,
            tracking_status: trackingStatus !== '' ? trackingStatus : null,
            acumatica_status: acumaticaStatus !== '' ? acumaticaStatus : null,
            warehouse: warehouse !== "" ? warehouse : null,
            limit: 8000,
            order_by: "upload_date",
            desc: true,
            on_pullsheet: trackingStatus === 200 ? true : null,
            order_valid: (trackingStatus !== 0 && orderStatus !== 199) ? true : null
        }
        console.log(params);
        try {
            let res = await orderHelpers.getDropshipOrders(props.company, params, props.token);
            let orders = await formatOrders(res);
            setOrders(orders);
            console.log('res', res)
            console.log(orders)
        }
        catch(err) {
            console.log(err);
        }
        
        props.setLoading(false);
    }

    const formatAcuOrderNum = (acuOrderNum) => {
        while (acuOrderNum.length < 7) {
            acuOrderNum = '0' + acuOrderNum;
        }
        return acuOrderNum;
    }

    const setOpenDetails = async(order = {}) => {
        if(Object.keys(order)?.length === 0 ) {
            setOpenDetail(order);
            setDetailsTab(0);
        }
        else {
            setOpenDetail(order.row);
            setDetailsTab(0)
        }
    }

    const setTab = async(e) => {
        console.log(e);
        let tab = Number(e.target.name);
        if(tab > detailsTab) setDirection("left");
        else setDirection("right")
        setDetailsTab(tab);
    }

    const exportOrders = async() => {
        let data = [
            ["acu_order_num", "po_number", "vendor_name", "upload_date", "order_date", "tracking_number", "internal_sku", "quantity", "zip_code", "build_status", "customer_name"].join(",")
        ];
        for(let order of orders) {
            for(let shipment of order.shipments) {
                for(let item of shipment.items) {
                    data.push(
                        [order.acu_order_num, order.internal_po_number, order.vendor_name, order.upload_date, order.order_date, shipment.tracking_number, item.internal_sku, 1, order.zip_code, order.order_status, order.name].join(",")
                    )
                }
            }
        }
        let csv = data.join("\n");
        let uri = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", "OrderSearchExport.csv");
        document.body.appendChild(a);
        a.click();
    }

    return(
        <Box className = {props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <OrderDetails 
                {...props} 
                direction = {direction} 
                setTab = {setTab} 
                tab = {detailsTab} 
                order = {openDetails} 
                setOpenDetails = {setOpenDetails} 
            />
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 2}}>
                <h1>Order Search</h1>
                <OrderQueryForm
                    isMobile = {props.isMobile}
                    startDate = {startDate?.full}
                    endDate = {endDate?.full}
                    vendorList = {vendorList}
                    vendor = {vendor}
                    setVendor = {setVendor}
                    acumaticaOrderNum = {acumaticaOrderNum}
                    internalPoNumber = {internalPoNumber}
                    externalPoNumber = {externalPoNumber}
                    sku = {sku}
                    name = {name}
                    trackingNumber = {trackingNumber}
                    uploadId = {uploadId}
                    pullsheetId = {pullsheetId}
                    warehouse = {warehouse}
                    setWarehouse = {setWarehouse}
                    orderStatus = {orderStatus}
                    setOrderStatus = {setOrderStatus}
                    acumaticaStatus = {acumaticaStatus}
                    setAcumaticaStatus = {setAcumaticaStatus}
                    trackingStatus = {trackingStatus}
                    setTrackingStatus = {setTrackingStatus}
                    handleChange = {handleChange}
                    handleDateChange = {handleDateChange}
                    getOrders = {getOrders}
                    orders = {orders}
                    clearAllFilters = {clearAllFilters}
                    exportOrders = {exportOrders}
                />
                <OrderSearchTable 
                    isMobile = {props.isMobile}
                    orders = {orders}
                    setOpenDetails = {setOpenDetails}
                />
            </Box>
        </Box>
    )
}

export default OrderSearch;