import Box from '@mui/material/Box';

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import WarehouseTable from './WarehouseTable'
import orderHelpers from '../../../helpers/orders';

function ReviewTable(props) {
    const [kyOrder, setKYOrder] = useState([]);
    const [paOrder, setPAOrder] = useState([]);
    const [oosOrder, setOosOrder] = useState([]);
    const [backOrder, setBackOrder] = useState([]);
    const [discOrder, setDiscOrder] = useState([]);
    const [incorrectOrder, setIncorrectOrder] = useState([]);
    const [smallPOOrder, setSmallPOOrder] = useState([]);

    useEffect(() => {
        let ky = [];
        let pa = [];
        let oos = [];
        let backorder = [];
        let discontinued = [];
        let incorrect = [];
        let smallPO = [];
        for(let order of props.orders) {
            for(let item of order.items) {
                if(item.decision === "Kentucky") {
                    item.fulfill_ky = item.quantity;
                    item.fulfill_pa = 0;
                }
                else if(item.decision === "Pennsylvania") {
                    item.fulfill_pa = item.quantity;
                    item.fulfill_ky = 0;
                }
                else {
                    switch(item.decision) {
                        case "OOS":
                            oos.push(item);
                            break;
                        case "Back Ordered":
                            backorder.push(item);
                            break;
                        case "Discontinued":
                            discontinued.push(item);
                            break;
                        case "Incorrect":
                            incorrect.push(item);
                            break;
                        case "Small PO":
                            smallPO.push(item);
                            break;
                    }
                    if(item.decision !== "Partial" && item.decision !== "Split")
                        continue;
                }
                if(item.fulfill_ky > 0) {
                    let temp = JSON.parse(JSON.stringify(item));
                    temp.quantity = item.fulfill_ky;
                    ky.push(temp);
                }
                if(item.fulfill_pa > 0) {
                    let temp = JSON.parse(JSON.stringify(item));
                    temp.quantity = item.fulfill_pa;
                    pa.push(temp);
                }
            }
        }
        setKYOrder(ky);
        setPAOrder(pa);
        setOosOrder(oos);
        setBackOrder(backorder);
        setDiscOrder(discontinued);
        setSmallPOOrder(smallPO);
        setIncorrectOrder(incorrect);
    }, [props.order])

    const acceptOrder = async() => {
        props.setLoading(true);
        let temp = props.orders;
        for(let order of temp) {
            for(let item of order.items) {
                if(item.decision === "Kentucky") item.ship_method = props.ky_method;
                else if(item.decision === "Pennsylvania") item.ship_method = props.pa_method;
                else if(item.decision === "Partial") {
                    item.ky_method = props.ky_method;
                    item.pa_method = props.pa_method;
                }
            }
            console.log("order", order);
            // await orderHelpers.saveBulkOrderDecisions(props.company + "_bulk", props.token, order, props.ignore_cancel)
            await orderHelpers.saveBulkOrderDecisions(props.company + "_bulk", props.token, order);

        }
        props.setLoading(false);
        props.setProcessing(false);
    }

    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} style={{width:"100%"}} display="flex">
            <Box display="flex" style={{"overflowX":"scroll", scrollbarWidth: "1px"}}>
            {kyOrder.length > 0 ? 
                <Box display="flex">
                    <WarehouseTable wh="Kentucky" {...props} order={kyOrder} ship_method={props.ky_method} />
                    <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                </Box>
            : null}
            {paOrder.length > 0 ? 
                <Box display="flex">
                    <WarehouseTable wh="Pennsylvania" {...props} order={paOrder} ship_method={props.pa_method} />
                    <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                </Box>
            : null}
            {oosOrder.length > 0 ?
                <Box display="flex">
                    <WarehouseTable wh="OOS" {...props} order={oosOrder}/>   
                    <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                </Box>         
            :null}
            {backOrder.length > 0 ? 
                <Box display="flex">
                    <WarehouseTable wh="Back Ordered" {...props} order={backOrder} />
                    <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                </Box>
            : null}
            {discOrder.length > 0 ? 
                <Box display="flex">
                    <WarehouseTable wh="Discontinued" {...props} order={discOrder} />
                    <Divider sx={{ml:"10px", mr:"10px"}} orientation="vertical" flexItem />
                </Box>
            : null}
             {incorrectOrder.length > 0 ? 
                <Box display = "flex">
                    <WarehouseTable wh = "Incorrect" {...props} order = {incorrectOrder} />
                    <Divider sx = {{ml:"10px", mr:"10px"}} orientation = "vertical" flexItem />
                </Box>
            : null}
             {smallPOOrder.length > 0 ? 
                <Box display = "flex">
                    <WarehouseTable wh = "Small PO" {...props} order = {smallPOOrder} />
                    <Divider sx = {{ml:"10px", mr:"10px"}} orientation = "vertical" flexItem />
                </Box>
            : null}
            </Box>
            {kyOrder.length > 0 || paOrder.length > 0 || oosOrder.length > 0 || backOrder.length > 0 || discOrder.length > 0 || incorrectOrder.length > 0 || smallPOOrder.length > 0 ?
                <Button size="large" onClick={()=>acceptOrder()} style={{height:"fit-content", position:"fixed", bottom:"30px", right:"30px", width:"200px"}} variant="contained" color="success">Submit</Button>
            : null}
        </Box>   
    )


}

export default ReviewTable;