import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';




function BulkTable(props) {

    useEffect(() => {
        console.log("HERE", props.rows)
    }, [props.rows]);

    let columns = [ 
        {
            field: 'id',
            headerName: "ID",
            flex: 1,
            editable: false,
            minWidth: 50,
            hide: true
        },
        {
            field: "acu_order_num",
            headerName: "SO Number",
            flex: 1,
            editable: false,
            minWidth: 150
        },
        {
            field: 'internal_po_number',
            headerName: 'PO Number',
            flex: 1,
            editable: false,
            minWidth: 150
        },
        {
            field: 'external_po_number',
            headerName: "External PO",
            flex: 1,
            editable: false,
            minWidth: 150
        },
        {
            field: 'vendor_name',
            headerName: 'Vendor',
            flex: 1,
            editable: false,
            minWidth: 200
        }, 
        {
            field: 'order_date',
            headerName: 'Date',
            renderCell: (params) => {
                let date = params.row?.order_date?.replace("T", " ").replace("Z", " ").replace(".000", "");
                return(
                    <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                )
            },
            flex: 1,
            editable: false,
            minWidth: 150
        }
    ]

    if(props.status === "New") {
        columns.push({
            field: 'pieces',
            headerName: 'Pieces',
            flex: 1,
            editable: false,
            minWidth: 100
        });
        delete columns[1]
    }
    else {
        columns.push({
            field: 'warehouse',
            headerName: "Warehouse",
            flex: 1, 
            editable: false,
            minWidth: 150
        })
        if(props.status === "Not In Acumatica") {
            delete columns[1]
        }
        else {
            columns.push({
                field: 'quantity',
                headerName: 'Total',
                flex: 1,
                editable: false,
                minWidth: 50
            })
            columns.push({
                field: 'built',
                headerName: 'Built',
                flex: 1,
                editable: false,
                minWidth: 50
            })
        }
        columns.push({
            field: 'order_status',
            headerName: "Status",
            flex: 1, 
            editable: false,
            minWidth: 100
        })
    }


    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} sx={{ 
                    '& .warning': {
                        bgcolor: (theme) => lighten("#FFFF00", 0.5)
                    },
                    '& .error': {
                        bgcolor: (theme) => lighten(theme.palette.warning.light, 0.5)
                    },
                    '& .severe': {
                        bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                    },
                    '& .new': {
                        bgcolor: (theme) => lighten(theme.palette.info.light, 0.5)
                    },
                    '& .inactive' : {
                        bgcolor: (theme) => lighten("#D3D3D3", 0.5)
                    }
                }}>
            <DataGrid
                rows={props.rows}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                // checkboxSelection = {true}
                onSelectionModelChange = {(ids) => {
                    console.log("ids", ids);
                    console.log("rows", props.rows);
                    let filtered = props.rows.filter((row) => {
                        return ids.includes(row.id) || ids.includes(row.id.toString());
                    })
                    console.log("filtered", filtered);
                    props.setSelectedRows(filtered);
                }}
                // rowSelectionModel={props.selectedRows}
                getRowClassName={(params) => params.row.class}
                sortModel = {[{field:'order_date', sort: 'desc'}]}
            />
        </Box>   
    )


}

export default BulkTable;